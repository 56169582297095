import commonHelper from '@/app/utility/common.helper.utility';
import advanceSearchModal from './advanceSearch';
import investorInvoicesDetails from './investorInvoicesDetails';
import masterleaseAgreement from '../../leases/masterAgreement';
import tenantLease from '../../leases/tenantLease';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
import URL_UTILITY from '../../../../../utility/url.utility';
export default {
  name: 'investorInvoices',
  watch: {
    currentPage: function() {
      this.getInvestorInvoicesList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvestorInvoicesList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  components: {
    commonHelper,
    advanceSearchModal,
    investorInvoicesDetails,
    masterleaseAgreement,
    tenantLease
  },
  data() {
    return {
      payload: null,
      selectAllCheckBox: false,
      showAdvanceSearchModal: false,
      showTenantInvoiceDetails: false,
      selectedMasterleaseAgreement: {
        value: null,
        text: null
      },
      selectedTenantLease: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      unsubscribe: null,
      showDetails: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      batchNumber: '',
      showValueSetModal: false,
      parent_value_set_id: null,
      searchParams: null,
      unitName: null,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      leaseUnitRowDetails: null,
      batch_num: null,
      gridData: [],
      gridFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'batch_number'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'source_name'
        },
        {
          key: 'bill_status_vset_meaning',
          label: 'Bill Status'
        },
        {
          key: 'bill_no'
        },
        {
          key: 'trx_type',
          label: 'Transaction Type'
        },
        {
          key: 'lease_agreement_no',
          label: 'MLA No.'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Tenant Lease No.'
        },
        {
          key: 'lease_investor_agreement_no',
          label: 'Investor Lease No.'
        },
        {
          key: 'lease_schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'bill_uom_meaning',
          label: 'Bill UOM'
        },
        {
          key: 'third_party_gstn',
          label: 'Third Party GSTN'
        },
        {
          key: 'first_party_gstn',
          label: 'First Party GSTN'
        },
        {
          key: 'due_date'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'billing_period_start_date',
          label: 'Billing From Date'
        },
        {
          key: 'billing_period_end_date',
          label: 'Billing End Date'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'vendor_id'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site_id'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'vendor_site_address'
        },
        {
          key: 'lease_prj_name',
          label: 'Lease Project Name'
        },
        {
          key: 'lease_tower_name',
          label: 'Lease Tower Name'
        },
        {
          key: 'lease_floor_name',
          label: 'Lease Floor Name'
        },
        {
          key: 'lease_unit_name',
          label: 'Lease Unit Name'
        },

        {
          key: 'fms_prj_name',
          label: 'FMS Project'
        },
        {
          key: 'fms_tower_name',
          label: 'FMS Tower'
        },
        {
          key: 'fms_floor_name',
          label: 'FMS Floor'
        },
        {
          key: 'fms_unit_name',
          label: 'FMS Unit'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'FMS Sub Unit'
        },
        {
          key: 'request_id',
          label: 'Request Num'
        },
        {
          key: 'error_msg'
        }
      ],
      billHdrId: null,
      modalName: null,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      calculateAmt: 0
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showTenantInvoiceDetails &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseInvoice/getInvestorInvoiceList',
            'investor-invoices',
            () => (this.loader = false)
          );
        }
        if (actionName === 'save') {
          this.processInvestorBillToApInvoices();
        }
        if (actionName === 'delete') {
          this.deleteInvestorRecoveryBill();
        }
      }
    });
  },
  methods: {
    getInvestorInvoicesList() {
      this.payload = {
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      if (!this.searchParams) {
        this.payload.searchParams = {
          lease_master_agreement_hdr_id: this.selectedMasterleaseAgreement
            .value,
          lease_tenant_agreement_hdr_id: this.selectedTenantLease.value,
          lease_investor_agreement_no: '',
          bill_status_vset: this.status.value,
          batch_number: this.batchNumber
        };
      } else {
        this.payload.searchParams = this.searchParams;
      }
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/getInvestorInvoiceList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page.map(data => {
              data.selectBox = false;
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    advanceSearchModal(flag) {
      this.showAdvanceSearchModal = flag;
    },
    advanceSearch(params) {
      this.searchParams = params;
      this.getInvestorInvoicesList();
      this.advanceSearchModal(false);
    },
    updateInvestorBatchNumber() {
      const filterBills = this.gridData.filter(key => key.selectBox);
      const details = filterBills.map(e => {
        return {
          investor_bill_hdr_id: e.bill_hdr_id,
          batch_number: this.batch_num
        };
      });

      const payload = {
        investor_bill_hdr: details ? details : null
      };

      this.loader = true;

      this.$store
        .dispatch('leaseInvoice/updateInvestorBatchNumber', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page.map(data => {
              data.selectBox = false;
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    processInvestorBillToApInvoices() {
      const filterBills = this.gridData.filter(key => key.selectBox);
      const details = filterBills.map(elem => {
        return {
          le_id: elem.le_id,
          module_id: store.state.shared.moduleId,
          ou_id: elem.ou_id,
          party_id: elem.vendor_id,
          party_site_id: elem.vendor_site_id,
          party_type: 'VENDOR',
          trx_date_from: null,
          trx_date_to: null,
          trx_id: elem.bill_hdr_id,
          trx_type_dtl_id: elem.trx_type_dtl_id,
          type: 'LEASE_INVESTOR_BILL'
        };
      });
      const payload = {
        ap_invoices: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/postInvestorBillApInvoices', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.selectedTenantLease.value = null;
      this.selectedTenantLease.text = null;
      this.selectedMasterleaseAgreement.text = null;
      this.selectedMasterleaseAgreement.value = null;
      this.status.value = null;
      this.status.text = null;
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    hideAddLeaseModal() {
      this.showAddLeaseUnitModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
        this.parent_value_set_id = this.leasePrj.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseTwr = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.leaseTwr.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.leaseFloor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.billHdrId = item.bill_hdr_id;
      this.showTenantInvoiceDetails = true;
    },
    closeLeaseUnitDetail() {
      this.showDetails = false;
    },
    updateLeaseUnitList() {
      this.leaseUnitData = [];
    },
    openTenantInvoiceModal(flag, modalName) {
      this.showTenantInvoiceDetails = flag;
      this.modalName = modalName;
    },
    selectedLeaseMaster(masterLease) {
      this.selectedMasterleaseAgreement.value =
        masterLease.lease_master_agreement_hdr_id;
      this.selectedMasterleaseAgreement.text = masterLease.lease_agreement_no;
      this.openTenantInvoiceModal(false, null);
    },
    selectedLeaseTenant(tenantLease) {
      this.selectedTenantLease.value =
        tenantLease.lease_tenant_agreement_hdr_id;
      this.selectedTenantLease.text = tenantLease.lease_tenant_agreement_no;
      this.openTenantInvoiceModal(false, null);
    },
    calculateAmount() {
      let sum = 0;
      this.gridData.forEach(element => {
        if(element.selectBox){
          sum+=parseFloat(element.total_amount.replaceAll(',', ''))
        }
        this.calculateAmt = parseFloat(sum).toFixed(2);
      });
    },
    selectBoxChecked(flag, index) {
      this.gridData[index].selectBox = flag;
      this.calculateAmount();
    },
    checkUncheckAll() {
      this.gridData = this.gridData.map(data => {
        if (data.request_id) {
          if (!/e/i.test(data.bill_status_vset)) {
            data.selectBox = false;
          } else {
            data.selectBox = this.selectAllCheckBox;
          }
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        return data;
      });
      this.calculateAmount();
    },
    validateCheckBox(row) {
      if (row.request_id) {
        if (!/e/i.test(row.bill_status_vset)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    mainSearch() {
      this.searchParams = null;
      this.getInvestorInvoicesList();
    },
    deleteInvestorRecoveryBill() {
      const filterData = this.gridData.filter(data => data.selectBox);
      const investorBill = filterData.map(elem => {
        return {
          bill_hdr_id: elem.bill_hdr_id
        };
      });
      const payload = {
        invoiceBill:
        {
          bill_dtls: investorBill ? investorBill : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/deleteInvestorRecoveryBill', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('leaseInvoice/saveAdvSearchFilters', null);
    // this.$store.state.leaseInvoice.leaseInvoice.adv_search = null;
  }
};
