import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import tenantLease from '../../../leases/tenantLease';
import commonHelper from '@/app/utility/common.helper.utility';
import Currency from '../../../../../admin/currency';
import party from '../../../../../generalLedger/party';
import masterAgreement from '../../../leases/masterAgreement';
import requestLease from '../../../../../request/submittedRequest';
import templateLease from '../../../../../request/template';
import store from '../../../../../../../store';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'advanceSearch',
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    tenantLease,
    masterAgreement,
    Currency,
    requestLease,
    templateLease,
    party
  },
  data() {
    return {
      showValueSetModal: false,
      parent_value_set_id: null,
      showCustomerSiteModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      showMasterLeaseAgreementList: false,
      showTenantLeaseList: false,
      openCurrencyModal: false,
      sourceName: {
        value: null,
        text: null
      },
      request: {
        value: null,
        text: null
      },
      template: {
        value: null,
        text: null
      },
      sourceNameList: [],
      transType: {
        value: null,
        text: null
      },
      transTypeList: [],
      batchNumber: '',
      billNo: null,
      scheduleType: {
        value: null,
        text: null
      },
      recoveryType: {
        value: null,
        text: null
      },
      billingPeriodUom: {
        value: null,
        text: null
      },
      masterLeaseAgreement: {
        value: null,
        text: null
      },
      tenantLease: {
        value: null,
        text: null
      },
      investorLease: '',
      billingUom: {
        value: null,
        text: null
      },
      dueDate: [],
      glDate: [],
      prjName: {
        value: null,
        text: null
      },
      towerName: {
        value: null,
        text: null
      },
      floorName: {
        value: null,
        text: null
      },
      unitName: {
        value: null,
        text: null
      },
      selectedCustomer: {
        name: null,
        id: null,
        siteName: null,
        siteId: null
      },
      currency: {
        name: null,
        id: null
      },
      customerId: null,
      customer_id: null,
      customer_name: null,
      customer_site_id: null,
      customer_site_name: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      startDate: null,
      endDate: null,
      start_date_end_date: [],
      amtFrom: null,
      amtTo: null,
      billingStatusList: [],
      selectedBillingStatus: {
        value: null,
        text: null
      },
      vsetCode: null,
      setTimeVsetCode: null,
      lease_agreement_no: null,
      lease_master_agreement_hdr_id: null,
      showCommonModal: false,
      modalType: null,
      leaseUnitData: [],
      leaseUnitFields: [
        {
          key: 'org_code'
        },
        {
          key: 'lease_name'
        },
        {
          key: 'lease_number'
        },
        {
          key: 'building_name'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'site_name'
        },
        {
          key: 'schedule_date'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'payment_purpose'
        },
        {
          key: 'period_name'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'invoice_no'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'tax_category'
        },
        {
          key: 'narration'
        },
        {
          key: 'location_code'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'transferred_by'
        },
        {
          key: 'return_msg'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'payment_term'
        },
        {
          key: 'property_under_notice'
        },
        {
          key: 'expense_month'
        },
        {
          key: 'zone'
        },
        {
          key: 'SAC'
        }
      ],
      legal_entity_name: null,
      legal_entity_id: null,
      operating_unit_name: null,
      operating_unit_id: null,
      fms_prj_name: null,
      fms_prj_id: null,
      fms_tower_name: null,
      fms_tower_id: null,
      fms_floor_name: null,
      fms_floor_id: null,
      fms_unit_name: null,
      fms_unit_id: null,
      fms_sub_unit_name: null,
      fms_sub_unit_id: null,
      isDependent: false,
      depVsetParam: null
    };
  },
  mounted() {
    const advFormData = this.$store.state.leaseInvoice.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
      this.getSourceNameList();
      this.getBillingStatus();
      this.getTxnTypeList();
      this.eventBus.$on('getCurrency', getCurrency => {
        this.currency.name = getCurrency.currency;
        this.currency.id = getCurrency.currency_id;
        this.openCurrencyModal = false;
      });
    }
  },
  methods: {
    getSourceNameList() {
      const payload = {
        module_id: store.state.shared.moduleId
      };
      this.$store
        .dispatch('leases/getSourceNameList', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const srcNameList = results.map(type => {
              return {
                value: type.source_hdr_id,
                text: type.source_name
              };
            });
            this.sourceNameList = srcNameList;
          }
        });
    },
    onSelectSourceType(event) {
      this.sourceName.text = event.text;
      this.sourceName.value = event.value;
    },
    getTxnTypeList() {
      const moduleId = store.state.shared.moduleId;
      this.$store.dispatch('leases/getTxnTypeList', moduleId).then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          const typeList = results.map(type => {
            return {
              value: type.trx_type_dtl_id,
              text: type.trx_desc
            };
          });
          this.transTypeList = typeList;
        }
      });
    },
    getBillingStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'BILL_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.billingStatusList = getValueAndText;
          }
        });
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'LEASE SCHEDULE TYPE') {
        this.scheduleType = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.recoveryType = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'UOM') {
        this.billingPeriodUom = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.prjName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.towerName = {
          value: null,
          text: null
        };
        this.floorName = {
          value: null,
          text: null
        };
        this.unitName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.towerName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.floorName = {
          value: null,
          text: null
        };
        this.unitName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.floorName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.unitName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.unitName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legal_entity_id = item.value_set_dtl_id;
        this.legal_entity_name = item.value_code;
        this.operating_unit_id = null;
        this.operating_unit_name = null;
        this.fms_prj_id = null;
        this.fms_prj_name = null;
        this.fms_tower_id = null;
        this.fms_tower_name = null;
        this.fms_floor_id = null;
        this.fms_floor_name = null;
        this.fms_unit_id = null;
        this.fms_unit_name = null;
        this.fms_sub_unit_id = null;
        this.fms_sub_unit_name = null;
        this.prjName = {
          value: null,
          text: null
        };
        // this.lease_prj_id = null;
        // this.lease_prj_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operating_unit_id = item.value_set_dtl_id;
        this.operating_unit_name = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.fms_prj_id = item.value_set_dtl_id;
        this.fms_prj_name = item.value_code;
        this.fms_tower_id = null;
        this.fms_tower_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.fms_tower_id = item.value_set_dtl_id;
        this.fms_tower_name = item.value_code;
        this.fms_floor_id = null;
        this.fms_floor_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.fms_floor_id = item.value_set_dtl_id;
        this.fms_floor_name = item.value_code;
        this.fms_unit_id = null;
        this.fms_unit_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.fms_unit_id = item.value_set_dtl_id;
        this.fms_unit_name = item.value_code;
        this.fms_sub_unit_id = null;
        this.fms_sub_unit_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.fms_sub_unit_id = item.value_set_dtl_id;
        this.fms_sub_unit_name = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET
      ) {
        this.selectedBillingStatus.text = item.value_meaning;
        this.selectedBillingStatus.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.batchNumber = item.value_code;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, valuseSetDtlId) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.prjName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.towerName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.parent_value_set_id = this.floorName.value;
      } else if (this.vsetCode === 'UOM') {
        this.parent_value_set_id = valuseSetDtlId;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.OU_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        this.parent_value_set_id = this.legal_entity_id;
        this.depVsetParam = this.operating_unit_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        this.parent_value_set_id = this.fms_prj_id;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        this.parent_value_set_id = this.fms_tower_id;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        this.parent_value_set_id = this.fms_floor_id;
        this.isDependent = true;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.parent_value_set_id = this.fms_unit_id;
        this.isDependent = true;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.openValueSetModal('UOM', findTimeObj.value_set_dtl_id);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedLeaseTenant(item) {
      this.tenantLease = {
        value: item.lease_tenant_agreement_hdr_id,
        text: item.lease_tenant_agreement_no
      };
      this.showHideCommonLeaseModal(false, null);
    },
    selectedLeaseMaster(item) {
      this.masterLeaseAgreement = {
        value: item.lease_master_agreement_hdr_id,
        text: item.lease_agreement_no
      };
      this.showHideCommonLeaseModal(false, null);
    },
    selectedRequestNum(item) {
      this.request = {
        value: item.request_id,
        text: item.name
      };
      this.showHideCommonLeaseModal(false, null);
    },
    selectedtemplate(item) {
      this.template = {
        value: item.template_id,
        text: item.template_name
      };
      this.showHideCommonLeaseModal(false, null);
    },
    selectedUserName(item) {
      this.selectedCustomer.name = item.party_name;
      this.getVendorIdByPartyId(item.party_id);
      this.showCommonModal = false;
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.selectedCustomer.id = response.data.data;
            this.getVendorSites();
          }
        });
    },
    getVendorSites() {
      this.$store
        .dispatch(
          'leaseSchedule/getVendorSiteByVendorId',
          this.selectedCustomer.id
        )
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
        });
    },
    searchInvestorParams() {
      const payload = {
        batch_number: this.batchNumber,
        bill_no: this.billNo,
        bill_status_vset: this.selectedBillingStatus.value,
        bill_uom: this.billingPeriodUom.value,
        billing_period_start_date: commonHelper.formattedDate(
          this.start_date_end_date[0]
        ),
        billing_period_end_date: commonHelper.formattedDate(
          this.start_date_end_date[1]
        ),
        due_date_from: commonHelper.formattedDate(this.dueDate[0]),
        due_date_to: commonHelper.formattedDate(this.dueDate[1]),
        fms_floor_id: this.fms_floor_id,
        fms_prj_id: this.fms_prj_id,
        fms_tower_id: this.fms_tower_id,
        fms_sub_unit_id: this.fms_sub_unit_id,
        fms_unit_id: this.fms_unit_id,
        gl_date_from: commonHelper.formattedDate(this.glDate[0]),
        gl_date_to: commonHelper.formattedDate(this.glDate[1]),
        le_id: this.legal_entity_id,
        lease_floor_id: this.floorName.value,
        lease_master_agreement_hdr_id: this.masterLeaseAgreement.value,
        lease_prj_id: this.prjName.value,
        lease_prj_unit_id: this.unitName.value,
        lease_schedule_type_vset: this.scheduleType.value,
        lease_tenant_agreement_hdr_id: this.tenantLease.value,
        lease_investor_agreement_no: this.investorLease,
        lease_tower_id: this.towerName.value,
        ou_id: this.operating_unit_id,
        recovery_type_vset: this.recoveryType.value,
        request_id: this.request.value,
        source_hdr_id: this.sourceName.value,
        total_amount_from: this.amtFrom,
        total_amount_to: this.amtTo,
        transactional_currency_id: this.currency.id,
        trx_type_dtl_id: this.transType.value,
        vendor_id: this.selectedCustomer.id,
        vendor_site_id: this.selectedCustomer.siteId
      };
      this.$emit('advanceSearch', payload);
      this.$store.dispatch('leaseInvoice/saveAdvSearchFilters', this);
      // this.$store.state.leaseInvoice.leaseInvoice.adv_search = this;
    },
    clearSearch() {
      this.sourceName = {
        value: null,
        text: null
      };
      this.transType = {
        value: null,
        text: null
      };
      this.masterLeaseAgreement = {
        value: null,
        text: null
      };
      this.tenantLease = {
        value: null,
        text: null
      };
      this.scheduleType = {
        value: null,
        text: null
      };
      this.recoveryType = {
        value: null,
        text: null
      };
      this.billingPeriodUom = {
        value: null,
        text: null
      };
      this.billNo = null;
      this.dueDate = [];
      this.glDate = [];
      this.selectedCustomer.name = null;
      this.selectedCustomer.siteName = null;
      this.currency.name = null;
      this.start_date_end_date = [];
      this.amtFrom = null;
      this.amtTo = null;

      this.legal_entity_id = null;
      this.legal_entity_name = null;
      this.operating_unit_id = null;
      this.operating_unit_name = null;
      this.fms_prj_id = null;
      this.fms_prj_name = null;
      this.fms_tower_id = null;
      this.fms_tower_name = null;
      this.fms_floor_id = null;
      this.fms_floor_name = null;
      this.fms_unit_id = null;
      this.fms_unit_name = null;
      this.fms_sub_unit_id = null;
      this.fms_sub_unit_name = null;
      this.prjName = {
        value: null,
        text: null
      };
      this.selectedBillingStatus = {
        value: null,
        text: null
      };
      this.prjName = {
        value: null,
        text: null
      };
      this.towerName = {
        value: null,
        text: null
      };
      this.floorName = {
        value: null,
        text: null
      };
      this.unitName = {
        value: null,
        text: null
      };
      this.request = {
        value: null,
        text: null
      };
      this.template = {
        value: null,
        text: null
      };
      this.investorLease = '';
      this.$store.dispatch('leaseInvoice/saveAdvSearchFilters', null);
      // this.$store.state.leaseInvoice.leaseInvoice.adv_search = null;
    },
    showHideCustomerSiteModal(flag) {
      if (this.selectedCustomer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Vendor First');
      }
    },
    selectedSite(item) {
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },
    setSearchItems(item) {
      this.sourceName = item.sourceName;
      this.request = item.request;
      this.template = item.template;
      this.sourceNameList = item.sourceNameList;
      this.transTypeList = item.transTypeList;
      this.scheduleType = item.scheduleType;
      this.recoveryType = item.recoveryType;
      this.billingPeriodUom = item.billingPeriodUom;
      this.masterLeaseAgreement = item.masterLeaseAgreement;
      this.tenantLease = item.tenantLease;
      this.investorLease = item.investorLease;
      this.billingUom = item.billingUom;
      this.dueDate = item.dueDate;
      this.glDate = item.glDate;
      this.prjName = item.prjName;
      this.towerName = item.towerName;
      this.floorName = item.floorName;
      this.unitName = item.unitName;
      this.selectedCustomer = item.selectedCustomer;
      this.currency = item.currency;
      this.customerId = item.customerId;
      this.customer_id = item.customer_id;
      this.customer_name = item.customer_name;
      this.customer_site_id = item.customer_site_id;
      this.customer_site_name = item.customer_site_name;
      this.startDate = item.startDate;
      this.endDate = item.endDate;
      this.start_date_end_date = item.start_date_end_date;
      this.amtFrom = item.amtFrom;
      this.amtTo = item.amtTo;
      this.billingStatusList = item.billingStatusList;
      this.selectedBillingStatus = item.selectedBillingStatus;
      this.legal_entity_name = item.legal_entity_name;
      this.legal_entity_id = item.legal_entity_id;
      this.operating_unit_name = item.operating_unit_name;
      this.operating_unit_id = item.operating_unit_id;
      this.fms_prj_name = item.fms_prj_name;
      this.fms_prj_id = item.fms_prj_id;
      this.fms_tower_name = item.fms_tower_name;
      this.fms_tower_id = item.fms_tower_id;
      this.fms_floor_name = item.fms_floor_name;
      this.fms_floor_id = item.fms_floor_id;
      this.fms_unit_name = item.fms_unit_name;
      this.fms_unit_id = item.fms_unit_id;
      this.fms_sub_unit_name = item.fms_sub_unit_name;
      this.fms_sub_unit_id = item.fms_sub_unit_id;
      this.batchNumber = item.batchNumber;
    }
  },
  destroyed() {
    this.eventBus.$off('getCurrency');
  }
};
